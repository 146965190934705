@import "../../index.scss";


.economy-post {
    .container {
        @include breakpoint(desktop) {
            max-width: getRem(1024);
        }
    }

    margin-top: getRem($priary-margin-top);
    margin-bottom: getRem($priary-margin-bot);

    .card {
        color: $gray-400  !important;
        border-radius: getRem($primary-radius);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        .img-square-wrapper img {
            border-radius: getRem($primary-radius) getRem($primary-radius) 0 0;
            height: getRem(480);
            width: 100%;
            object-fit: cover;
        }
        .chart-post-data{
            height: getRem(400);
        }
        hr{
            margin-top: getRem(3)!important;
            margin-bottom: getRem(5)!important;

        }
        .badge-primary {
            background-color: $primary !important;
            margin-right: getRem(5);
            margin-top: getRem(-50);

            a {
                color: white;

                &:hover {
                    
                    color: rgb(238, 238, 238);
                }
            }
        }

        .card-body {
            .post-date {
                font-family: 'Montserrat-Medium';
                font-size: getRem(12);

                margin-top: getRem(5);
                margin-bottom: getRem(16);

            }

            .card-text {
                font-size: getRem(20);
                @include breakpoint(desktop) {
                    font-size: getRem(14);
                }
                .wp-block-image img {
                    width: 100% !important;
                }
            }
        }
    }
}

.social-icon {
    margin-right: getRem(6);
    padding: getRem(2);
    svg {
        border-radius: getRem($primary-radius);

    }
}