@import "../../../../index.scss";


.nav-margin {}
.swiper-scrollbar{
  display: none;
}
.alerts-container{
  height: getRem(390);
  overflow: auto;
  .alert{
    b {
      font-size: getRem(16);
    }
    p {
      font-size: getRem(12);
    }
    .date{
      font-size: getRem(10);
      margin-top: getRem(5);;
    }
  }

}

.site-main-navbar {
  margin-bottom: getRem(75);

  @include breakpoint(desktop) {
    margin-bottom: getRem(70);
  }

  .nav-fixed {
    position: fixed !important;



    @include breakpoint(tablet_max) {
      margin-top: getRem(-30);

    }
    @include breakpoint(tablet) {
      margin-top: getRem(-30);

    }
    @include breakpoint(desktop) {
      margin-top: getRem(-30);
    }


    margin-top: getRem(-43);
    -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, .1);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, .1);
  }

  .container {
    @include breakpoint(desktop) {
      max-width: getRem(1024);
    }
  }

  .container-fluid {
    @include breakpoint(desktop) {
      max-width: getRem(1100);
    }

    @include breakpoint(tablet) {
      max-width: getRem(1100);
    }


  }

  .slider-container .container-fluid .swiper-slide {
    font-size: getRem(18);
  }

  .avIcon {
    @include breakpoint(desktop) {
      left: getRem(-40);
    }

    svg {
      color: rgb(182, 182, 182);
      transition: all 0.2s ease-in-out;
      &:hover{
        color: rgb(199, 199, 199);
      }
    }

  }

  .avIcon_red {
    @include breakpoint(desktop) {
      left: getRem(-40);
    }

    svg {
      transition: all 0.2s ease-in-out;
      color: rgb(243, 51, 51);
      &:hover{
        color: rgb(255, 93, 93);
      }
    }

  }

  .top-bar {
    padding: getRem(4) 0;
    background: #eee;
    font-size: getRem(14);


  }

  .iconClass {
    position: relative;

    svg {
      font-size: getRem(16) !important;
    }
  }

  .site-menu-toggle {
    svg {
      font-size: getRem(35);
      padding-right: getRem(30);
    }
  }

  .iconClass span {
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
  }

  .top-bar a {
    color: #666;

    @include breakpoint(mobile) {
      font-size: getRem(23);
    }
  }

  .site-navbar {
    margin-bottom: 0;
    z-index: 1999;
    position: relative;
    width: 100%;

    .dropdown-toggle {
      margin-left: getRem(-30);

      &::after {
        content: none;
        padding: 0;

      }

    }

    .dropdown-menu {
      margin-right: getRem(30) !important;
      margin-top: getRem(-10);
    }
  }

  @media (max-width:991.98px) {
    .site-navbar {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem
    }
  }

  .site-navbar .toggle-button {
    position: absolute;
    right: 0
  }

  .site-navbar .site-logo {

    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 0;
    margin: 0;
    padding: 0;
    z-index: 3;

    img {
      width: getRem(60);
    }
  }

  @media (max-width:991.98px) {
    .site-navbar .site-logo {
      float: left;
      position: relative
    }
  }

  .site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid #fff !important
  }

  .site-navbar .site-navigation .site-menu {
    margin-bottom: 0;
    padding-left: 0;
    font-size: getRem(15);

    svg:not(:root).svg-inline--fa,
    svg:not(:host).svg-inline--fa {
      font-size: getRem(10);
    }
  }

  .site-navbar .site-navigation .site-menu .active {
    color: #007bff !important
  }

  .site-navbar .site-navigation .site-menu a {
    text-decoration: none !important;
    display: inline-block
  }

  .site-navbar .site-navigation .site-menu>li {
    display: inline-block
  }

  .site-navbar .site-navigation .site-menu>li>a {
    margin-left: getRem(12);
    margin-right: getRem(12);
    padding: getRem(20) 0;
    color: #000 !important;
    display: inline-block;
    text-decoration: none !important;
    font-size: getRem(14);
  }

  .site-navbar .site-navigation .site-menu>li>a:hover {
    color: #007bff
  }

  .site-navbar .site-navigation .site-menu .has-children {
    position: relative
  }

  .site-navbar .site-navigation .site-menu .has-children>a {
    position: relative;
    padding-right: 20px;

  }

  .site-navbar .site-navigation .site-menu .has-children>a:before {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    position: absolute;
    text-align: left;
    border-top: getRem(2) solid #007bff;
    -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, .1);
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, .1);
    padding: 0 0;
    margin-top: 20px;
    margin-left: 0;
    background: #fff;
    -webkit-transition: .2s 0s;
    -o-transition: .2s 0s;
    transition: .2s 0s;
    font-size: getRem(12);

  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
    position: absolute
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    display: none;
    bottom: 100%;
    left: 20%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown a {
    text-transform: none;
    letter-spacing: normal;
    -webkit-transition: 0s all;
    -o-transition: 0s all;
    transition: 0s all;
    color: #000 !important
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown a.active {
    color: #007bff !important;
    background: #f8f9fa
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown>li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 200px
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown>li>a {
    padding: 9px 20px;
    display: block
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown>li>a:hover {
    background: #f8f9fa;
    color: #ced4da
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>a:before {
    content: "\e315";
    right: 20px
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown,
  .site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>ul {
    left: 100%;
    top: 0
  }

  .site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:hover>a,
  .site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:active>a,
  .site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:focus>a {
    background: #f8f9fa;
    color: #ced4da
  }

  .site-navbar .site-navigation .site-menu .has-children:hover>a,
  .site-navbar .site-navigation .site-menu .has-children:focus>a,
  .site-navbar .site-navigation .site-menu .has-children:active>a {
    color: #007bff
  }

  .site-navbar .site-navigation .site-menu .has-children:hover,
  .site-navbar .site-navigation .site-menu .has-children:focus,
  .site-navbar .site-navigation .site-menu .has-children:active {
    cursor: pointer
  }

  .site-navbar .site-navigation .site-menu .has-children:hover>.dropdown,
  .site-navbar .site-navigation .site-menu .has-children:focus>.dropdown,
  .site-navbar .site-navigation .site-menu .has-children:active>.dropdown {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    margin-top: 0;
    visibility: visible;
    opacity: 1
  }

  .site-mobile-menu {
    width: 100%;
    position: fixed;
    right: 0;
    z-index: 2000;
    background: #fff;
    height: calc(100vh);
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, .1);
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, .1);
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out
  }

  .site-mobile-menu {

    .site-mobile-menu-header {
      width: 100%;
      float: left;
      position: fixed;
      background-color: white;
      z-index: 100;
      box-shadow: 0 3px 7px -4px gray;
      margin-left: getRem(-10);
      padding: getRem(7);
      padding-right: getRem(34);

      svg {
        font-size: getRem(35);
        cursor: pointer;
      }

      .site-logo {
        padding-left: getRem(12);

        img {
          width: getRem(60);
          z-index: 9999 !important;
          height: hetRem(60);
        }
      }

    }
  }

  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
    float: right;
    margin-top: 8px
  }

  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
    font-size: 30px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 0;
    line-height: 1;
    cursor: pointer;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease
  }

  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
    color: #ced4da
  }

  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
    float: left;
    margin-top: 10px;
    margin-left: 0
  }

  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
    display: inline-block;
    text-transform: uppercase
  }

  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
    max-width: 70px
  }

  .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
    text-decoration: none
  }

  .site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 getRem(10) getRem(10) getRem(10);
    height: calc(100vh - 2rem);
    padding-bottom: getRem(200);
    z-index: 10000;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .site-mobile-menu .site-nav-wrap {
    -webkit-overflow-scrolling: touch;
    padding: 0;
    margin: 0;
    margin-top: getRem(105);
    list-style: none;
    position: relative
  }

  .site-mobile-menu .site-nav-wrap a {
    padding: getRem(10) getRem(5);
    display: block;
    position: relative;
    color: #212529
  }

  .site-mobile-menu .site-nav-wrap a:hover {
    color: #007bff
  }

  .site-mobile-menu .site-nav-wrap li {
    position: relative;
    display: block
  }

  .site-mobile-menu .site-nav-wrap li .nav-link.active {
    color: #007bff
  }

  .hide-overflow {
    overflow: hidden !important;
  }


  .site-mobile-menu .site-nav-wrap .arrow-collapse {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 20;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%
  }

  .site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
    background: #f8f9fa
  }

  .site-mobile-menu .site-nav-wrap .arrow-collapse:before {
    font-size: getRem(12);
    z-index: 20;
    font-family: "icomoon";
    content: "\f078";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
    -ms-transform: translate(-50%, -50%) rotate(-180deg);
    transform: translate(-50%, -50%) rotate(-180deg);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease
  }

  .site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }

  .site-mobile-menu .site-nav-wrap>li {
    display: block;
    position: relative;
    float: left;
    width: 100%
  }

  .site-mobile-menu .site-nav-wrap>li>a {
    padding-left: getRem(18);
    font-size: getRem(20);
  }

  .site-mobile-menu .site-nav-wrap>li>ul {
    padding: 0;
    margin: 0;
    list-style: none
  }

  .site-mobile-menu .site-nav-wrap>li>ul>li {
    display: block
  }

  .site-mobile-menu .site-nav-wrap>li>ul>li>a {
    padding-left: 40px;
    font-size: getRem(16)
  }

  .site-mobile-menu .site-nav-wrap>li>ul>li>ul {
    padding: 0;
    margin: 0
  }

  .site-mobile-menu .site-nav-wrap>li>ul>li>ul>li {
    display: block
  }

  .site-mobile-menu .site-nav-wrap>li>ul>li>ul>li>a {
    font-size: 16px;
    padding-left: 60px
  }

  .site-mobile-menu .site-nav-wrap[data-class="social"] {
    float: left;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 5em
  }

  .site-mobile-menu .site-nav-wrap[data-class="social"]>li {
    width: auto
  }

  .site-mobile-menu .site-nav-wrap[data-class="social"]>li:first-child a {
    padding-left: 15px !important
  }

  .sticky-wrapper {
    position: absolute;
    z-index: 100;
    width: 100%
  }



  .sticky-wrapper .site-navbar {
    background: #fff
  }

  .sticky-wrapper .site-navbar .site-logo a {
    color: #fff
  }

  .sticky-wrapper .site-navbar ul li a {
    color: rgba(255, 255, 255, .7) !important
  }

  .sticky-wrapper .site-navbar ul li a.active {
    color: #fff !important
  }

  .sticky-wrapper.is-sticky .site-navbar {
    background: #fff;
    box-shadow: 0 3px 7px -4px gray;
  }

  .sticky-wrapper.is-sticky .site-navbar .site-logo a {
    color: #007bff
  }

  .sticky-wrapper.is-sticky .site-navbar ul li a {
    color: #000 !important
  }

  .sticky-wrapper.is-sticky .site-navbar ul li a.active {
    color: #007bff !important
  }

  .sticky-wrapper .shrink {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  @media (max-width:991.98px) {
    .sticky-wrapper .shrink {
      padding-top: 30px !important;
      padding-bottom: 30px !important
    }
  }
}

.notificationList {
  width: getRem(250);

  .noneList {

    font-size: getRem(12);
    text-align: center;
  }

  .note-container {
    font-size: getRem(10);
    margin-bottom: getRem(-7);

    &:last-child {
      margin-bottom: getRem(-16);
    }

    b {
      p {
        margin-bottom: getRem(-5);
      }

    }
  }
}

.notificationContainer {}