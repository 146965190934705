@import "../../index.scss";

.cabinet-container {
  background-color: $gray-100;

  margin-top: getRem(30);
.notifications{
  height: getRem(166);
  .card{
    height: 100%;
    .card-header{
      background-color: rgb(219, 4, 4)!important;
      height: getRem(45)!important;
    }
  }
}
  .container {
    @include breakpoint(desktop) {
      max-width: getRem(1140);
    }

    .row {}

    .col-my {
      margin-top: getRem(40);
      margin-bottom: getRem(20);
      margin-left: getRem(10);
      @include breakpoint(desktop) {
        margin-top: getRem(40);
        margin-bottom: getRem(20);
        margin-left: 0;
      }
      h2 {
        font-family: "Montserrat-Bold";
        text-transform: uppercase;
        font-weight: 700;
        font-size: getRem(20);
        @include breakpoint(desktop) {
          font-size: getRem(14);
        }
      }

      .list-group {
        margin-top: getRem(20);

        .list-group-item {
          background-color: $gray-100;
          padding: getRem(8) 0;
          border: none;
          font-size: getRem(18);
          @include breakpoint(desktop) {
            font-size: getRem(12);
          }
          .link{
            color: $cabinet-link-primary;
          }
        }
      }

      .card {
        color: $gray-400 !important;
        border-radius: getRem($primary-radius);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

        .card-header {
          background-color: $primary;
          border-radius: getRem($primary-radius) getRem($primary-radius) 0 0;
          color: $white;
          font-size: getRem(20);
          height: getRem(75);
          @include breakpoint(desktop) {
          font-size: getRem(14);
          height: getRem(55);
          }
          p {
            font-size: getRem(16);
            @include breakpoint(desktop) {
            font-size: getRem(10);
            }
          }
        }

        .card-body {
          .row {
            height: getRem(70);
            @include breakpoint(desktop) {
            height: getRem(55);
            }
          }

          .dergee {
            font-size: getRem(50);
            @include breakpoint(desktop) {
            font-size: getRem(38);
            }
          }

          .high-low-data {
            font-size: getRem(17);
            @include breakpoint(desktop) {
              font-size: getRem(14);
              }

            span {
              font-size: getRem(14);
              @include breakpoint(desktop) {
                font-size: getRem(12);
                }
            }
          }
        }

      }
    }
  }

  .weather-data {
    margin-top: getRem(0);
    margin-bottom: getRem(-15);
    @include breakpoint(desktop) {
      margin-top: getRem(-3);
      margin-bottom: getRem(-15);
      }
    svg {
      height: getRem(35);
      width: getRem(35);
      margin: getRem(8) getRem(10);
      @include breakpoint(desktop) {
      height: getRem(27);
      width: getRem(27);
      margin: getRem(5);
      margin-left: getRem(15);
      }
    }

    .col-10 {
      padding-left: getRem(40);

      h3 {
        font-size: getRem(20);
        font-family: "Montserrat-Medium";
        flex-direction: row;
        @include breakpoint(desktop) {
        font-size: getRem(14);
        }
        span {
          font-family: "Montserrat-Bold";
          font-size: getRem(18);
          @include breakpoint(desktop) {
          font-size: getRem(14);
          }
        }
      }
    }

    p {
      font-size: getRem(15);
      @include breakpoint(desktop) {
      font-size: getRem(12);
      }
    }
  }
}