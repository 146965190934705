@use 'sass:math';

$black: #000000;
$gray-100: #F8F8F8;
$gray-200: #cccccc;
$gray-400: #6C6C6C;
$gray-900: #5a5a5a;
$primary: #4285F4;
$white: #ffffff;
$cabinet-link-primary: #212539;
$primary-radius: 5;
$priary-margin-top: 90;
$priary-margin-bot: 100;

@function getRem($size) {
  $remSize: math.div($size, 16);
  @return #{$remSize}rem;
}

@mixin breakpoint($point) {
  @if $point ==mobile {
    @media screen and (max-width: 576px) {
      @content;
    }
  }

  @else if $point ==mobile_landscape {

    @media screen and (min-width: 667px),
    screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  }

  @else if $point ==tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  }
  @else if $point ==tablet_max {
    @media screen and (min-width: 1200px) {
      @content;
    }
  }

  @else if $point ==tablet_landscape {

    @media screen and (min-width: 992px),
    screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  }

  @else if $point ==desktop {
    @media screen and (min-width: 1200px) {
      @content;
    }
  }
}

html {
  font-size: calc(10 * 100vw / 320);
  width: calc(0vw + 100%);
  font-family: "Montserrat-Regular";

  @include breakpoint(mobile_landscape) {
    font-size: calc(10 * 100vw / 667);
  }

  @include breakpoint(tablet) {
    font-size: calc(12 * 100vw / 768);
  }

  @include breakpoint(tablet_landscape) {
    font-size: calc(14 * 100vw / 960);
  }

  @include breakpoint(tablet_max) {
    font-size: calc(14 * 100vw / 1600);
  }

  @include breakpoint(desktop) {
    font-size: calc(16 * 100vw / 1440);
  }

  body {
    margin: 0;
    font-family: "Montserrat-Regular";
    overflow-x: hidden!important;
    &:before {
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
    }
  }
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/static/Montserrat-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/static/Montserrat-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/static/Montserrat-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/static/Montserrat-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Fa-Brands";
  src: url("./assets/fonts/static/fa-brands-400.ttf");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Fa-Regular";
  src: url("./assets/fonts/static/fa-regular-400.ttf");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Fa-Solid";
  src: url("./assets/fonts/static/fa-solid-900.ttf");
  font-style: normal;
  font-weight: 100;
}

.Toastify__toast {
  border-radius: getRem(7) !important;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;

  .Toastify__toast-body {
    color: $gray-900;
  }

}

.Toastify__toast--error {
  background: white !important;

  .Toastify__progress-bar {
    background: #ff3547;
  }
}
.Toastify__toast-theme--light {
  background: white !important;
}


.link {
  background: linear-gradient(0deg, $primary, $primary) no-repeat right bottom / 0 var(--bg-h);
  transition: background-size 350ms;
  padding-bottom: 3px;
  --bg-h: 2px;
  margin-bottom: 4px;
}
.link:where(:hover, :focus-visible) {
  background-size: 100% var(--bg-h);
  background-position-x: left;
}
.swiper-button-disabled{
  background-size: 100% var(--bg-h);
  background-position-x: left;
  cursor: default;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
}
.category-link{
  font-weight: 500;
  color: $gray-900;
  font-size: getRem(18);
  @include breakpoint(desktop) {
      font-size: getRem(12);
  }
}
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 1; }
body.offcanvas-menu:before {
  opacity: 1;
  z-index: 1400!important;
  visibility: visible; }

  .offcanvas-menu .site-main-navbar .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
  .site-navbar-target{
    z-index: 3000;
  }
  .back-to-top{
    font-size: getRem(18);
    font-weight:300;
  }
  .scroll-to-top {
    background-color: white;
    right: getRem(40);
    bottom: getRem(40);
    position: fixed;
    z-index: 2000;
    cursor: pointer;
    border-radius:getRem(7);
    width: getRem(50);
    height: getRem(50);
    box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
    border: none;
}

.card-body  strong{
  font-weight: 700!important;
}
.button-contact {
  border-radius: getRem(16);
  font-size: getRem(10);
  padding: 0.5rem 1.25rem 0.5rem!important;
}
.red {
  background-color: #ff3547;
}
::-webkit-scrollbar {
  background-color:#fff;
  width:getRem(16) 
}

::-webkit-scrollbar-track {
  background-color:#fff
}
::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}
::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:getRem(16);
  border:getRem(5) solid #fff
}
::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:getRem(4)  solid #f4f4f4
}
::-webkit-scrollbar-button {display:none}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;

}
.modal-open{
  @include breakpoint(desktop) {
    padding-right: getRem(16)!important;
  }
}
body.modal-open{
  @include breakpoint(desktop) {
    padding-right: getRem(16)!important;
  }
}
.modal-open .site-navbar {
  @include breakpoint(desktop) {
    padding-right: getRem(16)!important;
  }

}
.form_menu{
  margin-top: getRem(10);
}
.modal-width-container{
  @include breakpoint(desktop) {
    max-width: getRem(300)!important;
  }
  @include breakpoint(mobile) {
    max-width: getRem(320)!important;
  }
}
.modal {
  padding-right: getRem(16)!important;
  padding-left: getRem(16)!important;
}