@import "../../index.scss";

.containerError {
    margin-top: getRem(40);
    margin-bottom: getRem(40);

    .container {
        .header {
            font-size: getRem(100);

        }
    }

}