@import "../../../../index.scss";

.card-blog {

  .card {
    color: $gray-400  !important;
    border-radius: getRem($primary-radius);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    @include breakpoint(desktop) {
      .img-square-wrapper img {
        border-radius: getRem($primary-radius) 0 0 getRem($primary-radius);
        height: getRem(200);


      }
    }
    .col {
      padding: 0;
    }
    @include breakpoint(mobile) {
      .img-square-wrapper img {
        height: getRem(320);
        object-fit: cover;
        border-radius: getRem($primary-radius) getRem($primary-radius) 0 0;


      }
    }

    .card-title {
      font-weight: 600;
      color: $gray-900;
      font-size: getRem(24);

      @include breakpoint(desktop) {
        font-size: getRem(15);
      }
    }

    .card-header {
      background-color: $primary;
      border-radius: getRem($primary-radius) getRem($primary-radius) 0 0;
      color: $white;
      font-size: getRem(20);

      height: getRem(60);

      p {
        font-size: getRem(12);
      }
    }

    .card-body {
      height: 100%;
      position: relative;
      font-size: getRem(16);
      
      .card-text {
        
        padding-bottom: getRem(10);
        p {
          font-size: getRem(20);
        }
      }

      @include breakpoint(desktop) {

        padding: getRem(15) getRem(15) getRem(15) getRem(0);
        .card-text {
          
          padding-bottom: 0;
          p {
            font-size: getRem(14);
          }
        }
      }

      .card-footer {
        background-color: $white;
        padding: getRem(10) getRem(0) getRem(10) getRem(15);
        position: absolute;
        bottom: 0;
        width: 100%;
        font-size: getRem(16);
        left: getRem(20);
        @include breakpoint(mobile) {
          width: calc(100% - 2rem);
          a{
            margin-right: getRem(-25);
          }
         
        }
        @include breakpoint(desktop) {
          font-size: getRem(12);
          left: getRem(0);
         
        }


      }
    }
  }
}