@import "../../index.scss";

.blog-post{
    .container {
        @include breakpoint(desktop) {
          max-width: getRem(1140);
        }}
    margin-bottom: getRem(100);
    .card {
        color: $gray-400  !important;
        border-radius: getRem($primary-radius);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          .img-square-wrapper img {
            border-radius: getRem($primary-radius) getRem($primary-radius) 0 0 ;
            height: 100%;
            width: 100%;
        }
        .badge-primary{
            background-color: $primary!important;
            margin-right: getRem(5);
            margin-top: getRem(-50);
           a{
            color: white;
            &:hover{
                color: rgb(238, 238, 238);
            }
           }
        }
        .card-body {
            .post-date{
                font-family:'Montserrat-Medium';
                font-size: getRem(12);
                margin-top: getRem(5);
                margin-bottom: getRem(16);
                
            }
        }
    }
}
.social-icon{
    margin-right: getRem(6);
    padding: getRem(2);
    button{  
    }
    svg{
        border-radius: getRem($primary-radius);
        
    }
}