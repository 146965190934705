@import "../../index.scss";

.blog-container {
  background-color: $white;
  margin-top: getRem(30);
  .container {
    @include breakpoint(desktop) {
      max-width: getRem(1024);
      .news-header {
        font-size: getRem(48);
      }
    }
    .row {
    }
    .news-header{
      font-size: getRem(36);
      @include breakpoint(desktop) {
        font-size: getRem(48);
      }
    }
  }
}
.btn-blog button {
  margin-top: getRem(40);
  margin-bottom: getRem(30);
  background: #4285F4;
  border-radius: getRem(25);
  text-transform: none!important;
  color: $white;

  padding: getRem(12) getRem(30) getRem(12);
  font-size: getRem(20) !important;

  @include breakpoint(desktop) {
    font-size: getRem(14) !important;
    padding: getRem(8) getRem(20) getRem(8);
    margin-top: getRem(40);
  }
  &:hover {
    color: $white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
}