@import "../../index.scss";

.table-post {
  .container {
    margin-top: getRem(25);
    @include breakpoint(desktop) {
      max-width: getRem(1140);
    }
  }


  margin-top: getRem($priary-margin-top);
  margin-bottom: getRem($priary-margin-bot);

  .card {
    color: $gray-400  !important;
    border-radius: getRem($primary-radius);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    .datatable-entries{
      width: getRem(0);
    }
    div.dataTables_wrapper div.dataTables_filter{
      text-align: left;

      input{
        width: 97%;
        @include breakpoint(desktop){
          @include breakpoint(tablet) {
            margin-left: getRem(-358)
          }
        }
        @include breakpoint(tablet) {
          margin-left: getRem(-358)
        }
        
      }
    }
  }

}
