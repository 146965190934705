@import "../../../../index.scss";

.search-item {
  margin-top: getRem(20);

  .section {
    .card {
      border-radius: getRem($primary-radius);

      hr{
        margin-top: getRem(-5);
        margin-bottom: getRem(-3);
      }
      .single-post:last-child {
        margin-bottom: getRem(16);
      }
      h6{
        margin-top: getRem(5);
        font-size: getRem(16);
        @include breakpoint(desktop) {
            font-size: getRem(16);
        }
      }
    }
    .list-group {
      box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
      border-radius: getRem($primary-radius);
      font-size: getRem(20);
      @include breakpoint(desktop) {
          font-size: getRem(16);
      }

    }
    .list-group .list-group-item:first-child {
      border-top-left-radius: getRem($primary-radius);
      border-top-right-radius: getRem($primary-radius);
      
    }
    .img-blog{
      height: getRem(60);
      object-fit: cover;
      width: 100%;
    }
    .list-group .list-group-item:last-child {
      border-bottom-right-radius: getRem($primary-radius);
      border-bottom-left-radius: getRem($primary-radius);
  }
  }
  .font-small{
    font-size: getRem(14);
    @include breakpoint(desktop) {
        font-size: getRem(12);
    }
    margin-top: getRem(-20);
  }
}