@import "../../../../index.scss";

.footer-main {
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);

    .container {
        padding: 20px;
        @include breakpoint(desktop) {
            max-width: getRem(1024);
        }
        .row {
            .col-foo  {
                .block {
                    display: flex;

                    img {
                        width: getRem(80);
                        margin-top: getRem(10);
                        @include breakpoint(desktop) {
                            width: getRem(60);
                        }
                    }

                    h5 {
                        font-family: "Montserrat-Medium";
                        font-size: getRem(20)!important;
                        @include breakpoint(desktop) {
                            font-size: getRem(12)!important;
                        }
                        
                        width: getRem(100);
                        margin: auto;
                        margin-left: getRem(10);
                        @include breakpoint(mobile) {
                            width: 100%;
                            margin-top: getRem(40);
                            padding-left: getRem(10);
                            font-size: getRem(18);
                        }
                    }
                }

            }
        }
    }

    .container-fluid {

        background-color: #F8F8F8;

        .container {
            @include breakpoint(desktop) {
                max-width: getRem(1024);
            }

            .row {
                padding-bottom: getRem(40);
                background-color: #F8F8F8;

                .col-foo {
                    margin-top: getRem(30);

                    h4 {
                        font-family: "Montserrat-Bold";
                        font-size: getRem(14);
                        font-weight: 700;
                        color: #0A142F;
                        text-transform: uppercase;
                        @include breakpoint(mobile) {
                            font-size: getRem(20);
                        }
                    }

                    h2 {}

                    .list-group-item {
                        max-width: getRem(220);
                        background-color: #F8F8F8;
                        border: 0;
                        font-size: getRem(10);
                        color: #0A142F;
                        padding: getRem(4) 0;
                        @include breakpoint(mobile) {
                            font-size: getRem(20);
                            max-width: getRem(320);
                            padding: getRem(6) 0;
                        }
                        a{
                            color: black;
                            &:hover{
                                color: #007bff;
                            }
                        }
                    }
                }

            }
        }
    }
}

.footer {
    svg{
        margin-bottom: getRem(10);
        border: 0.03rem solid $gray-400;
        width:getRem(13);
        height: getRem(13);
        border-radius: 50%;
        padding: getRem(7);
        margin-top: getRem(-10);
        @include breakpoint(mobile) {
            width:getRem(25);
            height: getRem(25);
            padding: getRem(10);
        }
        
    }
    width: 100%;
    height: getRem(60);
    background-color: $white;
    border-top: getRem(0.5) solid $gray-200;
    .text-center{
        padding-top: getRem(10);
        color: #0A142F;
        h5{
            font-size: getRem(12);
            @include breakpoint(mobile) {
                font-size: getRem(20);
            }
        }
        p{
            font-size: getRem(10);
            @include breakpoint(mobile) {
                font-size: getRem(16);
            }
        }
    }
}