@import "../../index.scss";

.share-buttons {
    .header {
        margin-bottom: getRem(-20);
        font-size: getRem(12);
        margin-top: getRem(-10);
    }

    .buttons {
        margin-bottom: getRem(20);
    }

    .share-btn-icon img {
        width: getRem(20);
        height: getRem(20);
        color: black;
    }
}

.share-buttons-page {
    width: 100%;
    background-color: $primary;
    border-radius: getRem($primary-radius) getRem($primary-radius) 0 0;

    .header {
        margin-bottom: getRem(-20);
        font-size: getRem(12);
        margin-top: getRem(-10);
    }

    .buttons {
        padding: getRem(7) getRem(8) getRem(5);
    }

    svg {
        width: getRem(20);
        height: getRem(20);
        color: white !important;
    }
}