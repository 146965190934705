@import "../../index.scss";

.contacts-map-container {
  margin-top: getRem(100);
  .container {
    @include breakpoint(desktop) {
      max-width: getRem(1140);
    }}
  .full-container {
    width: 100%;
    background-color: #4285F4;
    margin-bottom: getRem(40);
    margin-top: getRem(40);
    .info-box{
      h1{
        font-family: "Montserrat-Light";
        font-size: getRem(36);
        color: $white;
        font-weight: 100;
        padding-top: getRem(60);
        width: getRem(360);
      }
      button{
        background-color: white!important;
        border-radius: getRem(20);
        font-size: getRem(12);
        padding: getRem(10);
        margin-top: getRem(40);
        margin-bottom: getRem(40);
        color: $gray-900;
        font-weight: 500;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        &:hover, &:active{
          background-color: rgb(235, 235, 235)!important;
          color: $gray-900!important;
        }
      }
    }
    .info-map{
      .card{
        height: getRem(230);
        margin-top: getRem(30);
        margin-bottom: getRem(30);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        iframe{
          border: 0;
          border-radius: getRem($primary-radius);
        }
      }
    }
  }

  .container {
    h1 {
      font-size: getRem(48);
    }

    .row {
      --bs-gutter-x: 3.5rem;

      @include breakpoint(mobile) {
        --bs-gutter-x: 1.5rem;
      }
    }

    .card {
      background-color: #F9F9F9;
      margin-top: getRem(20);
      margin-bottom: getRem(20);
      font-family: "Montserrat-Medium";
      border-radius: getRem($primary-radius);
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

      .card-body {

        .card-title {

          svg {
            background-color: #4285F4;
            border-radius: 30%;
            height: getRem(30);
            width: getRem(30);
            color: $white;
            padding: getRem(10);
          }
        }

        .card-text {
          font-size: getRem(20);
        }
      }

      .card-info {
        display: flex;
        flex-flow: column wrap;

        .map-links {
          width: getRem(270);
          font-size: getRem(14);
          color:#007bff;
          &-map {
            margin-top: getRem(28);
          }
        }

        span {
          font-size: getRem(12);
          color: #888888;

          margin-top: getRem(10);

        }

        a {
          font-size: getRem(14);
        }
      }
    }
  }
}