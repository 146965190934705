@import "../../index.scss";

.slider-container {
  margin-top: getRem(-10);
  .swiper-pagination-bullet {
    border-radius: 0 !important;
    width: getRem(30);
    height: getRem(3);
  }

  .container-fluid {
    margin: 0 !important;
    padding: 0 !important;

    .swiper {
      width: 100%;
      height: 80vh;
    }

    .swiper-slide {
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      .block {
        position: relative;
        height: 100%;
        width: 100%;

        @include breakpoint(desktop) {
          width: getRem(500);
        }

        background-color: #2125299c;
        z-index: -1;

        .info {
          z-index: 10;

          img {
            width: getRem(90);
            height: getRem(90);

            @include breakpoint(desktop) {
              width: getRem(105);
              height: getRem(105);
              margin: getRem(40) getRem(35) getRem(40);
            }

            margin: getRem(45) getRem(35) getRem(40);


          }

          .text {
            font-family: "Montserrat-Light";
            color: #fff;
            font-weight: 300;
            font-size: getRem(24);
            margin: getRem(50) getRem(-30) getRem(40);

            @include breakpoint(desktop) {
              font-size: getRem(20);
              margin: getRem(55) getRem(15) getRem(40);
            }

            p {
              padding-top: getRem(12);
              font-size: getRem(16);

              @include breakpoint(desktop) {
                padding-top: getRem(10);
                font-size: getRem(14);
              }
            }

          }

          .more-info {
            padding: getRem(55) getRem(35) getRem(40);
            color: #fff;
          }
        }

        .bot-links {
          margin-left: getRem(25);

          button {
            font-family: "Montserrat-Light";
            font-weight: 300;
            margin-top: getRem(16);
            background: #4285F4;
            border-radius: getRem(30);
            text-transform: none !important;
            color: $white;
            padding: getRem(12) getRem(30) getRem(12);
            font-size: getRem(20) !important;

            @include breakpoint(desktop) {
              font-size: getRem(14) !important;
              padding: getRem(8) getRem(20) getRem(8);
              margin-top: getRem(10);
            }

            &:hover {
              color: $white;
              box-shadow: rgba(105, 105, 105, 0.3) 0px 1px 2px 0px, rgba(97, 97, 97, 0.15) 0px 2px 6px 2px;
            }

          }

          :nth-child(2) {
            button {
              background: #e6e6e6 !important;
              color: rgb(20, 20, 20) !important;
            }
          }
        }


      }
    }

    .swiper-slide img {
      position: absolute;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

    }

  }

}