@import "../../index.scss";

.container404 {
    margin-top: getRem($priary-margin-top);
    margin-bottom: getRem($priary-margin-bot);

    .container {
        .header {
            font-size: getRem(100);

        }
    }

}