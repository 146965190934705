@import "../../../../index.scss";

.cards-container{
  .newRelise{
    b {
      font-size: getRem(16);
    }
    h6 {
      font-size: getRem(14);
    }
    .eddit, a{
      font-size: getRem(11)!important;
    }
  }
  .swiper-pagination{
    margin-top: getRem(100)!important;
  }
  .note-container{
    max-width: getRem(1220);
    margin: auto;
    padding-left: getRem(15);
    padding-right: getRem(15);
    padding-bottom: getRem(15);
    font-size: getRem(14);
    strong{
      font-weight: 600;
    }

  }
    .container{
          
      @include breakpoint(desktop) {
        max-width: getRem(1220);
      }
      
      .row{
        --bs-gutter-x: 3.5rem;
        @include breakpoint(mobile) {
          --bs-gutter-x: 1.5rem;
        }
      }
    }
    .card{
        background-color: $gray-100;
        border-radius: getRem($primary-radius);
        margin-top: getRem(25);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        .card-title{
            font-family: "Montserrat-Medium";
            font-weight: 500;
            color:$black;
            font-size: getRem(20);
            height: getRem(50);
            @include breakpoint(desktop) {
            font-size: getRem(14);
            height: getRem(50);
            }
        }
        .card-text{
          font-size: getRem(16)!important;
          @include breakpoint(desktop) {
            font-size: getRem(11)!important;
            height: getRem(35);
          }
            
        }
        a{
          font-size: getRem(18);
          @include breakpoint(desktop) {
            font-size: getRem(14);
            color:$primary;
          }
        }
    }
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}