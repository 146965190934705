@import '../../index.scss';

.blog-main {
  .container {
    @include breakpoint(desktop) {
      max-width: getRem(1140);
    }}
    .button {
      margin-top: getRem(40);
      margin-bottom: getRem(30);
      text-transform: none!important;
      background: #4285F4;
      border-radius: getRem(25);
      color: white;
      padding: getRem(8);
      font-size: getRem(12)!important;
      width: getRem(50);
      &:hover {
        color: white;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      }
    }
    margin-top: getRem($priary-margin-top);
    margin-bottom: getRem($priary-margin-bot);
}



