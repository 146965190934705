@import "../../index.scss";

.person-post {
  .container {
    @include breakpoint(desktop) {
      max-width: getRem(1140);
    }
  }


  margin-top: getRem($priary-margin-top);
  margin-bottom: getRem($priary-margin-bot);

  .card {
    color: $gray-400  !important;
    border-radius: getRem($primary-radius);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    .img-square-wrapper img {
      border-radius: getRem($primary-radius) getRem($primary-radius) 0 0;
      height: getRem(600);
      width: 100%;


      @include breakpoint(desktop) {
        border-radius: getRem($primary-radius);
        height: getRem(280);
        margin: getRem(20);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      }
    }

    .person-header {
      margin: getRem(20);
    }

    .badge-primary {
      background-color: $primary  !important;
      margin-right: getRem(5);
      margin-top: getRem(-50);

      a {
        color: white;

        &:hover {
          color: rgb(238, 238, 238);
        }
      }
    }

    .card-body {

      .post-date {
        font-family: 'Montserrat-Medium';
        font-size: getRem(12);
        margin-top: getRem(5);
        margin-bottom: getRem(16);

      }

      .card-text {
        .wp-block-image img {
          width: 100% !important;

        }
      }
    }
  }
}

.leader-list {
  .container {
    @include breakpoint(desktop) {
      max-width: getRem(1140);
    }

    .card-list {
      margin-top: getRem(25);

      .card {
        border-radius: getRem($primary-radius);

        .view {
          cursor: pointer;
        }

        .view.view-cascade {
          border-radius: getRem($primary-radius) getRem($primary-radius) 0 0;
        }

        .card-body {
          padding: getRem(10) getRem(5) getRem(10) getRem(5);
          height: getRem(110);

          &-job {
            font-weight: 500;
            font-size: getRem(12);
            @include breakpoint(mobile) {
              font-size: getRem(18);
            }
          }
        }

        .card-img-top {
          height: getRem(600);
          object-fit: cover;

          @include breakpoint(desktop) {
            height: getRem(260);
          }
        }

        .card-title {
          font-family: 'Montserrat-Medium';
          font-weight: 600;
          color: $gray-900;
          font-size: getRem(15);
          text-align: center;
          @include breakpoint(mobile) {
            font-size: getRem(24);
          }
        }
      }

      .col-sm-12,
      .col-md-6 {
        margin-bottom: getRem(20);
        @include breakpoint(mobile) {
          margin-bottom: getRem(30);
        }
      }
    }

  }

  margin-top: getRem($priary-margin-top);
  margin-bottom: getRem($priary-margin-bot);
}

.social-icon {
  margin-right: getRem(6);
  padding: getRem(2);

  svg {
    border-radius: getRem($primary-radius);

  }
}