@import "../../index.scss";

.population-module {
  .container {
    margin-top: getRem(25);

    @include breakpoint(desktop) {
      max-width: getRem(1140);
    }
    .accordion {
      margin-left: -15px;
      margin-right: -15px;
      div.dataTables_wrapper div.dataTables_filter {
        text-align: left;
  
        input {
          width: 97%;
          margin-top: getRem(-30);
          margin-bottom: getRem(-30);
          @include breakpoint(tablet) {
            margin-left: getRem(-312)
          }
  
        }
      }
      .accordion-item {
        hr{
          
          margin: getRem(7);
          background-color: $gray-100;
          width: 100%;
        }
        h2{
          font-size: 0;
        }


        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);

        &:first-of-type {
          border-radius: 0;

          .accordion-button {
            border-radius: 0;
          }
        }

        &:last-of-type {
          border-radius: 0 0 getRem($primary-radius) getRem($primary-radius);
          border-bottom: 0;
          margin-bottom: getRem(-4);

          .accordion-button {
            border-radius: 0 0 getRem($primary-radius) getRem($primary-radius);
          }
        }

        .accordion-button {
          &::after {
            filter: invert(100%) sepia(87%) saturate(132%) hue-rotate(128deg) brightness(112%) contrast(88%);
          }
          &:focus{
            box-shadow: 0 0 0;

          }
        }

        .accordion-header {
          button {
            background-color: $primary;
            color: $white;
            width: 100%;
            height: 100%;
            border: 0;
            font-family: "Montserrat-Medium";
            font-size: getRem(16);
            margin-top: getRem(-3);
            padding: getRem(10) getRem(30) getRem(10) getRem(30);
          }
          margin-bottom:  getRem(3);
        }
      }
      .accordion-body{
        padding: getRem(10) getRem(30) getRem(10) getRem(30);
        .row{
          font-size: getRem(12);
          margin-bottom: getRem(10);
          margin-top: getRem(10);
          span{
            font-family: "Montserrat-Medium";
            font-weight: 500;
          }
        }
      }
    }
  }


  margin-top: getRem($priary-margin-top);
  margin-bottom: getRem($priary-margin-bot);

  .card {
    color: $gray-400  !important;
    border-radius: getRem($primary-radius) getRem($primary-radius) 0 0 ;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  }


}